@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
body,
html {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
}

a {
  text-decoration: none;
  text-transform: uppercase;
}

.navigation {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1rem;
  background-color: #373739;
  text-align: center;
}

.navigation a {
  font-size: 1rem;
  color: #f5b31b;

  margin: 0 1rem;
}

.bgimage1 {
  /* The image used */
  background-image: url('images/tap.png');

  /* Full height */
  height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bgimage2 {
  /* The image used */
  background-image: url('images/living-room.png');

  /* Full height */
  height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bgimage3 {
  /* The image used */
  background-image: url('images/kitchen.png');

  /* Full height */
  height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.front-logo {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.front-logo img {
  height: 30%;
}

.content {
  padding: 2rem 8rem;
}

.footer {
  position: relative;
  height: 20rem;
  background-color: #373739;
  display: flex;
  padding: 2rem;
}

.footerContent {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.left {
  display: flex;
  flex-direction: column;
}

.left h2 {
  color: #fff;
}

.left a {
  color: #ccc;
}

.right img {
  height: 4rem;
}

.abn {
  padding-top: 2rem;
  color: #fff;
  /* position: absolute;
  bottom: 1rem;
  right: 1rem; */
}

.phone {
  color: #ccc;
  text-decoration: none;
  text-transform: uppercase;
}

.janchris {
  width: 60%;
}

@media only screen and (max-width: 400px) {
  .navigation {
    padding: 0.5rem 0rem;
  }

  .content {
    padding: 1rem 1.5rem;
  }

  .front-logo img {
    height: 23%;
  }

  .footerContent {
    display: block;
  }

  .left {
    padding-bottom: 2rem;
  }
  .abn {
    padding: 0;
  }
  .janchris {
    width: 100%;
  }
}
